import * as React from 'react';
import './errors.scss';
import _ from 'lodash'

type ErrorPageProps = {
    code: string | number;
    text: string;
    availablePrismicBlogs: any[];
}

type ErrorPageState = {
    progress: number;
    showProgress: boolean;
    pageContext?: any;
}

class ErrorPage extends React.Component<ErrorPageProps, ErrorPageState> {
    counter: any;

    constructor(props: ErrorPageProps) {
        super(props);

        this.state = {
            progress: 0,
            showProgress: true,
        };
    }

    componentDidMount() {
        this.counter = setInterval(() => this.timer(), 20);
    }

    timer() {
        const progress = this.state.progress + 1;
        this.setState({
            progress,
        });
        if (progress >= 100) {
            clearInterval(this.counter);
            this.setState({
                showProgress: false
            })
        }
    }

    render() {
        const { code, text } = this.props;
        const makeBlogLink = ({ slug, title }) => {
            return <a href={`/blog/post/${slug}`} className="blog-link">{title}</a>;
        }

        return (
            <div className="error-page fixed">
                <a className="fixed logo" href="/">
                    <img src='/images/logo.png' alt="AmazeeIO logo"/>
                </a>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="error-code">{code}</div>
                            <div className="error-text">{text}</div>

                            {this.state.showProgress && (<div className="loading-bar">
                                <div className="inner" style={{ maxWidth: `${this.state.progress}%` }} />
                            </div>)}

                            <div className="results" style={{opacity: this.state.showProgress ? 0 : 1}}>
                                <h4 className="mt-8">Maybe check out some of our blog posts?</h4>
                                {this.props.availablePrismicBlogs.map((p, i) => <React.Fragment key={i}>{makeBlogLink(p)}</React.Fragment>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default ErrorPage;