import * as React from "react";
import "../blog/style.scss";
import AmazeePage from "../components/layout";
import AmazeeIoMetadata from "../components/metadata";
import ResuableBody from "../components/reusable";
import Helmet from "react-helmet";
import { PageTitle } from "../components/title/title";
import { PrismicImage } from "../utils/queries/types";
import usePreviewData from "../utils/usePreviewData";
import "./person.scss";
import "./landing-page.scss";

const LandingPage: React.FC<{
  location: Location;
  pageContext: {
    page_title: string;
    body: any;
    page_meta_thumbnail?: PrismicImage;
    page_meta_description?: string;
  };
}> = ({ pageContext, location }) => {
  const liveData = usePreviewData(pageContext, "prismicLandingPage", true);
  const {
    page_title,
    body,
    meta_title,
    page_meta_thumbnail,
    page_meta_description,
    canonical_url,
  } = liveData;

  const title = meta_title ? meta_title : page_title;

  return (
    <AmazeePage location={location} id="landing_page">
      <Helmet>
        {canonical_url && <link rel="canonical" href={canonical_url} />}
      </Helmet>
      <PageTitle title={title} />
      <AmazeeIoMetadata
        title={title}
        page_meta_thumbnail={page_meta_thumbnail}
        page_meta_description={page_meta_description}
        location={location}
      />
      <ResuableBody body={body} />
    </AmazeePage>
  );
};

export default LandingPage;
