import * as React from 'react';
import ErrorPage from '../components/error-page';
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import LandingPage from '../templates/landing-page'
import BlogPostPage from '../templates/blog-post'

const NotFoundPage = ({ pageContext: { availablePrismicBlogs } }) => {
    return <ErrorPage availablePrismicBlogs={availablePrismicBlogs} code='404' text='Page not found' />
}
export default withUnpublishedPreview(NotFoundPage, {
    templateMap: {
      landing_page: LandingPage,
      blog_post: BlogPostPage,
    },
  });